import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import EditNoteIcon from "@mui/icons-material/EditNote";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Box, Card, List, ListItem, ListItemText, Rating, Stack, Typography } from "@mui/material";
import { User as UserAvatar } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import FeedbackModal from "./feedbackModal";

import style from "../../pages/project/components/candidateProfileCard/candidateProfileCard.module.scss";
import ProfileTab from "../project/components/profileTab/ProfileTab";

import { CriteriaTag } from "@/components/VettingCriteriaRating";
import { updateCandidatesData } from "@/store/reducers/hiring-manager/hiringManager.reducer";
import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { Avatar, Tooltip } from "@/ui";

interface CandidateDetailsProps {
    candidate: any;
}

function CandidateDetails({ candidate }: CandidateDetailsProps) {
    const dispatch = useDispatch();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const isViewMode = searchParams.get("isViewOnly") === "true";
    const [activeTab, setActiveTab] = useState(0);
    const [isLiked, setIsLiked] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);
    const [status, setStatus] = useState<"" | "Shortlisted" | "Rejected">("");
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const [hovered, setHovered] = useState(false);

    useEffect(() => {
        setIsLiked(candidate?.candidateDetails?.[0]?.hiringManager?.action === "ACCEPTED" ? true : false);
        setIsDisliked(candidate?.candidateDetails?.[0]?.hiringManager?.action === "REJECTED" ? true : false);
        setStatus(
            candidate?.candidateDetails?.[0]?.hiringManager?.action === "PENDING"
                ? ""
                : candidate?.candidateDetails?.[0]?.hiringManager?.like
                    ? "Shortlisted"
                    : "Rejected"
        );
    }, [candidate]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const handleLike = () => {
        setIsLiked(!isLiked);
        setStatus("Shortlisted");
        if (isDisliked) setIsDisliked(false);

        dispatch(
            updateCandidatesData({
                uuid: params.id,
                candidateReviews: [
                    {
                        candidateId: candidate?.candidateDetails?.[0]?._id,
                        action: !isLiked ? "ACCEPTED" : "PENDING",
                        review: "",
                        rating: !isLiked ? 5 : 3,
                        like: !isLiked,
                        reasons: [],
                        userName: "Hiring_Manager",
                    },
                ],
            })
        );
    };

    const handleDislike = () => {
        setIsDisliked(!isDisliked);
        setStatus("Rejected");
        if (isLiked) setIsLiked(false);

        dispatch(
            updateCandidatesData({
                uuid: params.id,
                candidateReviews: [
                    {
                        candidateId: candidate?.candidateDetails?.[0]?._id,
                        action: !isDisliked ? "REJECTED" : "PENDING",
                        review: "",
                        rating: !isDisliked ? 1 : 3,
                        like: isDisliked,
                        reasons: [],
                        userName: "Hiring_Manager",
                    },
                ],
            })
        );
    };

    const handleCopyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        dispatch(setSuccessNotification("Copied to clipboard!"));
    };

    const candidateProfile = {
        name: candidate?.candidateDetails?.[0]?.name ?? "",
        profileImage: candidate?.candidateDetails?.[0]?.profileImage ?? "",
    };

    return (
        <div style={{ flex: 1, padding: "20px" }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                    padding: 1,
                    overflow: "hidden",
                }}
            >
                <Typography variant="h5" className="font-semibold">
                    Candidate Details
                </Typography>

                <Stack
                    direction="row"
                    spacing={2}
                    style={{
                        opacity: isViewMode ? 0.5 : 1,
                        cursor: isViewMode ? "not-allowed" : "pointer",
                    }}
                >
                    <Tooltip
                        title={
                            !isLiked && !isDisliked
                                ? "Please like or dislike candidate to give feedback"
                                : "Give feedback"
                        }
                    >
                        <button
                            onClick={() => {
                                setFeedbackOpen(true);
                                setHovered(false);
                            }}
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}
                            style={{
                                marginTop: "5px",
                                display: "flex",
                                alignItems: "center",
                                minWidth: isLiked || isDisliked ? "160px" : "0px",
                                color: "#0891B2",
                                border: isLiked || isDisliked ? "1px solid #0891b2" : "none",
                                textTransform: "none",
                                padding: "4px 12px",
                                transition: "all 0.4s ease",
                                width: isLiked || isDisliked ? "fit-content" : "0px",
                                overflow: "hidden",
                                justifyContent: "flex-start",
                                fontSize: "22px",
                                height: "35px",
                                opacity: isLiked || isDisliked ? 1 : 0,
                                visibility: isLiked || isDisliked ? "visible" : "hidden",
                                borderRadius: "4px",
                                pointerEvents: isViewMode ? "none" : "auto",
                            }}
                        >
                            <EditNoteIcon
                                style={{
                                    transition: "margin-right 0.4s ease",
                                    fontSize: "28px",
                                    marginRight: isLiked || isDisliked ? "8px" : "0",
                                }}
                            />
                            <p className="ml-2 text-sm whitespace-nowrap italic">Add feedback</p>
                        </button>
                    </Tooltip>

                    <Box
                        onClick={handleLike}
                        sx={{
                            borderRadius: "50%",
                            p: 1,
                            transition: "all 0.2s ease-in-out",
                            cursor: "pointer",
                            "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                                transform: "scale(1.1)",
                            },
                            pointerEvents: isViewMode ? "none" : "auto",
                        }}
                    >
                        <ThumbUpIcon sx={{ fontSize: "x-large", color: isLiked ? "#03B043" : "#828283" }} />
                    </Box>

                    <Box
                        onClick={handleDislike}
                        sx={{
                            borderRadius: "50%",
                            p: 1,
                            transition: "all 0.2s ease-in-out",
                            cursor: "pointer",
                            "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                                transform: "scale(1.1)",
                            },
                            pointerEvents: isViewMode ? "none" : "auto",
                        }}
                    >
                        <ThumbDownIcon sx={{ fontSize: "x-large", color: isDisliked ? "#e20027" : "#828283" }} />
                    </Box>
                </Stack>
            </Box>

            <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2, mb: 2 }}>
                <Avatar
                    src={candidate?.candidateDetails?.[0]?.profileImage}
                    alt={candidate?.candidateDetails?.[0]?.name}
                    className={style["spcard__card-avatar"]}
                    style={{ backgroundColor: "#8cc0e3" }}
                    fallback={<UserAvatar />}

                />
                <Box sx={{ flex: 1, display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                    <div>
                        <p className="font-bold text-[16px]"> {candidate?.candidateDetails?.[0]?.name}</p>
                        <p className="font-semibold text-[14px]">
                            <span className="font-semibold">Location:</span>{" "}
                            <span className="font-normal text-gray-700">
                                {candidate?.candidateDetails?.[0]?.location}
                            </span>
                        </p>

                        <div className="flex items-center gap-2">
                            {candidate?.candidateDetails?.[0]?.profileUrl && (
                                <button
                                    onClick={() => window.open(candidate?.candidateDetails?.[0]?.profileUrl, "_blank")}
                                >
                                    <LinkedInIcon style={{ color: "#0c66c2" }} />
                                </button>
                            )}

                            {/* {(candidate?.candidateDetails?.[0]?.email?.personal?.length > 0 ||
                                candidate?.candidateDetails?.[0]?.email?.professional?.length > 0) && (
                                    <Tooltip
                                        type="light"
                                        title={
                                            <>
                                                <div style={{ fontSize: "12px", marginBottom: "3px" }}>
                                                    {candidate?.candidateDetails?.[0]?.email?.personal?.length > 0 && (
                                                        <>
                                                            <strong style={{ color: "#00599a", fontSize: "13px" }}>
                                                                Personal email
                                                            </strong>
                                                            {candidate?.candidateDetails?.[0]?.email?.personal?.map(
                                                                (item: any) => (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: 4,
                                                                            marginTop: "4px",
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            <strong className="mr-4">{item}</strong>
                                                                            <ContentCopyIcon
                                                                                sx={{
                                                                                    fontSize: "12px",
                                                                                    "&:hover": {
                                                                                        color: "black",
                                                                                    },
                                                                                }}
                                                                                onClick={() => handleCopyToClipboard(item)}
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}

                                                    {candidate?.candidateDetails?.[0]?.email?.professional?.length > 0 && (
                                                        <>
                                                            <p
                                                                style={{
                                                                    color: "#00599a",
                                                                    fontSize: "13px",
                                                                    marginTop: "14px",
                                                                    fontWeight: "600",
                                                                }}
                                                            >
                                                                Professional email
                                                            </p>
                                                            {candidate?.candidateDetails?.[0]?.email?.professional?.map(
                                                                (item: any) => (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: 4,
                                                                            marginTop: "4px",
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            <strong className="mr-4">{item}</strong>
                                                                            <ContentCopyIcon
                                                                                sx={{
                                                                                    fontSize: "12px",
                                                                                    "&:hover": {
                                                                                        color: "black",
                                                                                    },
                                                                                }}
                                                                                onClick={() => handleCopyToClipboard(item)}
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </>
                                        }
                                    >
                                        <MailIcon />
                                    </Tooltip>
                                )}

                            {candidate?.candidateDetails?.[0]?.phone?.length > 0 && (
                                <Tooltip
                                    type="light"
                                    title={
                                        <>
                                            <div style={{ fontSize: "12px", marginBottom: "3px" }}>
                                                <strong style={{ color: "#00599a", fontSize: "13px" }}>
                                                    Phone number
                                                </strong>
                                                {candidate?.candidateDetails?.[0]?.phone.map((item: any) => (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            gap: 4,
                                                            marginTop: "4px",
                                                        }}
                                                    >
                                                        <p>
                                                            <strong className="mr-4">{item}</strong>
                                                            <ContentCopyIcon
                                                                sx={{
                                                                    fontSize: "12px",
                                                                    "&:hover": {
                                                                        color: "black",
                                                                    },
                                                                }}
                                                                onClick={() => handleCopyToClipboard(item)}
                                                            />
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    }
                                >
                                    <PhoneIcon />
                                </Tooltip>
                            )} */}
                        </div>
                    </div>

                    <Stack direction="row" alignItems="center">
                        <p className="font-semibold text-[#6C6C6C] mr-3">Candidate Rating: </p>
                        <Rating
                            className="cursor-pointer pointer-events-none"
                            value={candidate?.candidateDetails?.[0]?.starRating?.value || 0}
                            precision={0.5}
                        />
                    </Stack>
                    {/* ) : null} */}
                </Box>
            </Box>

            <Box sx={{ mt: 2 }}>
                <Card variant="outlined">
                    {candidate?.candidateDetails?.[0]?.starRating.description?.length ? (
                        <>
                            <p className="text-lg font-semibold text-gray-800 p-1"> AI Summary</p>
                            <hr></hr>
                            <List>
                                {candidate?.candidateDetails?.[0]?.starRating.description.map(
                                    ({ text, intent, criterion, tag }, index) => {
                                        return (
                                            <ListItem
                                                key={index}
                                                secondaryAction={
                                                    intent ? (
                                                        <Tooltip title="Good to have">
                                                            <CriteriaTag variant={tag}>
                                                                <DoneRoundedIcon htmlColor="#44B700" />
                                                            </CriteriaTag>
                                                        </Tooltip>
                                                    ) : (
                                                        <CriteriaTag variant={tag}>
                                                            <WarningRoundedIcon htmlColor="#ED6C02" />
                                                        </CriteriaTag>
                                                    )
                                                }
                                            >
                                                {!criterion ? (
                                                    <ListItemText
                                                        sx={{
                                                            "& .MuiTypography-root": {
                                                                fontSize: 14,
                                                            },
                                                        }}
                                                        primary={`• ${text}`}
                                                    />
                                                ) : (
                                                    <Tooltip title={`Criterion: ${criterion}`} arrow>
                                                        <ListItemText
                                                            sx={{
                                                                "& .MuiTypography-root": {
                                                                    fontSize: 14,
                                                                },
                                                            }}
                                                            primary={`• ${text}`}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </ListItem>
                                        );
                                    }
                                )}
                            </List>
                        </>
                    ) : null}
                </Card>
            </Box>

            <Box sx={{ mt: 2 }}>
                <ProfileTab data={candidate?.candidateDetails?.[0]} />
            </Box>
            <FeedbackModal
                onClose={() => setFeedbackOpen(false)}
                open={feedbackOpen}
                status={status}
                candidate={candidate}
            />
        </div>
    );
}

export default CandidateDetails;

// messages={candidate?.emailConversationData}
// chats={candidate?.linkedinConnectionData}
