import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import style from "./projectCard.module.scss";

import Checkbox from "../../../../components/Checkbox/Checkbox";
import { FallbackUI } from "../../../../components/ErrorBoundary/ErrorBoundary";
import { useHighlightText } from "../../../../hooks/useHighlightText";
import handleCleverTap from "../../../../utils/clevertap";
import CandidateProfileCard from "../candidateProfileCard/CandidateProfileCard";

import { setActiveCandidateInfoModalIndex } from "@/store/reducers/candidates/Candidates.reducer";
import {
    editCandidate,
    removeSelectedCandidate,
    selectCandidateCurrentPage,
    selectSearchQuery,
    selectSelectedCandidates,
    setScrollToSkills,
    setSelectedCandidates,
} from "@/store/reducers/project/project.reducer";
import { ICandidate } from "@/store/reducers/project/project.types";

export const FallbackCandidateCard = () => {
    return (
        <div className={style["spcard"]}>
            <div className={`${style["spcard__container"]}`}>
                <div className={`${style["spcard__card"]}`}>
                    <FallbackUI />
                </div>
            </div>
        </div>
    );
};

type ProjectCardProps = {
    data: ICandidate;
    index: number;
    isSample?: boolean;
};

export default function ProjectCard(props: ProjectCardProps) {
    const { _id } = props.data;
    const [searchParams, setSearchParams] = useSearchParams();

    const [, setSeeMore] = useState(false);

    const { id: projectId } = useParams();
    const candidateSearchId = searchParams.get("candidateId");
    const dispatch = useDispatch();
    const selectedCandidates = useSelector(selectSelectedCandidates);
    const candidatePageNo = useSelector(selectCandidateCurrentPage);
    const searchQuery = useSelector(selectSearchQuery);

    useHighlightText([searchQuery], "main_container");

    const showModal = () => {
        dispatch(setActiveCandidateInfoModalIndex(props.index));
    };

    useEffect(() => {
        if (candidateSearchId === _id) {
            showModal();
            searchParams.delete("candidateId");
            setSearchParams(searchParams);
        }
    }, []);

    const handleSelectCandidate = (e) => {
        handleCleverTap("Select candidate", {
            checked: e.target.checked,
            "Project id": projectId,
        });

        if (e.target.checked) {
            dispatch(
                setSelectedCandidates({
                    candidateId: [_id],
                    pageNo: candidatePageNo,
                })
            );
        } else {
            dispatch(removeSelectedCandidate(_id));
        }
    };

    const isSelected = Boolean(selectedCandidates.find((item: any) => item.candidateId === _id));

    const handleSeeMore = () => {
        dispatch(setActiveCandidateInfoModalIndex(props.index));
        setSeeMore((prev) => !prev);
        dispatch(setScrollToSkills(true));
    };

    let backgroundColor = 'white';

    switch (props?.data?.hiringManager?.action) {
        case 'ACCEPTED':
            backgroundColor = '#f8fffc';
            break;
        case 'REJECTED':
            backgroundColor = '#fff8f896';
            break;
        default:
            backgroundColor = 'white';
    }


    return (
        <div className={style["spcard"]} id={`card${props.index}`}>
            <div className={`${style["spcard__container"]}`}>
                <Checkbox checked={isSelected} onChange={handleSelectCandidate} />
                <div
                    className={`${style["spcard__card"]} ${isSelected && style["spcard__card-selected"]}`}
                    onClick={showModal}
                    style={{ marginRight: "10px", backgroundColor: backgroundColor }}
                >
                    <CandidateProfileCard
                        variant="card"
                        projectId={projectId}
                        index={props.index}
                        candidate={props.data}
                        handleSeeMore={handleSeeMore}
                        onEditCandidate={(data) => dispatch(editCandidate(data))}
                    />
                </div>
            </div>
        </div>
    );
}
