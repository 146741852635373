import { Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import BaseModal from "@/components/BaseModal";
import { shareWithHiringManager } from "@/store/reducers/hiring-manager/hiringManager.reducer";
import { checkIfLoading } from "@/store/reducers/loaders.reducer";
import { Button } from "@/ui";

interface ShareListModalProps {
    open: boolean;
    onClose: () => void;
    details: any;
}

function ShareListModal({ open, onClose, details }: ShareListModalProps) {
    const dispatch = useDispatch();
    const params = useParams();
    const uuid = params.id;

    const [hiringManagerName, setHiringManagerName] = useState(details?.prefilledName || "");
    const [hiringManagerEmail, setHiringManagerEmail] = useState(details?.prefilledEmail || "");

    useEffect(() => {
        setHiringManagerName(details?.prefilledName || "");
        setHiringManagerEmail(details?.prefilledEmail || "");
    }, [open]);

    const handleShare = () => {
        dispatch(
            shareWithHiringManager({
                hiringManagerName: hiringManagerName,
                hiringManagerEmail: hiringManagerEmail,
                uuid: uuid,
            })
        );
        onClose();
    };

    const isLoading = useSelector(checkIfLoading(shareWithHiringManager.type));

    return (
        <>
            {open ? (
                <BaseModal
                    onClose={onClose}
                    overlayStyles={{
                        padding: "1.7rem 1.5rem",
                        width: 525,
                    }}
                    hideCloseButton={false}
                >
                    <p style={{ fontSize: "25px", fontWeight: "600", marginBottom: "1.75rem" }}>
                        Share with hiring manager?
                    </p>
                    <p style={{ fontSize: "14px", marginLeft: "2px" }}>Enter hiring manager name</p>

                    <TextField
                        value={hiringManagerName}
                        fullWidth
                        size="small"
                        onChange={(e) => setHiringManagerName(e.target.value)}
                    />

                    <p style={{ fontSize: "14px", marginLeft: "2px", marginTop: "20px" }}>
                        Enter hiring manager email address
                    </p>

                    <TextField
                        value={hiringManagerEmail}
                        fullWidth
                        size="small"
                        onChange={(e) => setHiringManagerEmail(e.target.value)}
                    />

                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={1}
                        sx={{ marginTop: "2rem" }}
                    >
                        <p style={{ fontSize: "12px", fontStyle: "italic", color: "gray" }}>
                            You are about to share this list with your Hiring manager. Please ensure that the email is
                            correct.
                        </p>

                        <Button
                            variant="default"
                            style={{ borderRadius: "4px", fontSize: "12px", border: "none", minWidth: "fit-content" }}
                            onClick={handleShare}
                            disabled={isLoading}
                        >
                            {isLoading ? "Sending..." : " Yes, share"}
                        </Button>
                    </Stack>
                </BaseModal>
            ) : null}
        </>
    );
}

export default ShareListModal;
