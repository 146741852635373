import { MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import HiringManagerIcon from "../../../../assets/img/hiring-manager.png";

import { MaterialProvider } from "@/components/MaterialProvider";
import {
    addCandidatesToHiringManager,
    getListName,
} from "@/store/reducers/hiring-manager/hiringManager.reducer";
import { Popover, Tooltip } from "@/ui";

interface ValueItem {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    tooltip?: string;
}

interface SendToHiringManagerProps {
    fromSuperInbox?: boolean;
    candidateParamsId?: string;
    projects?: any;
}

function SendToHiringManagerFromSuperInbox({ projects }: SendToHiringManagerProps) {
    const params = useParams();
    const dispatch = useDispatch();
    const candidateParamsId = params.candidate_id;
    const projectId = projects[0].value.toString();
    const [openSI, setOpenSI] = useState<boolean>(false);

    useEffect(() => {
        dispatch(
            getListName({
                projectId,
            })
        );
    }, [projectId, openSI]);

    const handleSuperInboxClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        if (projects.length === 1) {
            const payload = {
                candidateIds: [candidateParamsId],
                name: `Hiring-Manager-List-${projectId}`,
                projectId: projectId,
            };
            dispatch(addCandidatesToHiringManager(payload));
            return;
        }

        setOpenSI(true);
    };

    const handleProjectSelect = (projectId: string) => {
        dispatch(getListName({ projectId }));
        const payload = {
            candidateIds: [candidateParamsId],
            name: `Hiring-Manager-List-${projectId}`,
            projectId: projectId,
        };
        dispatch(addCandidatesToHiringManager(payload));
        setOpenSI(false);
    };

    return (
        <>
            <Tooltip title="Add to Hiring manager list">
                <button style={{ borderRadius: "20px" }} className="rounded-full" onClick={handleSuperInboxClick}>
                    <img src={HiringManagerIcon} alt="HM" style={{ width: 25, height: 25, marginBottom: "5px" }} />
                </button>
            </Tooltip>

            <MaterialProvider>
                {projects?.length > 1 && (
                    <Popover
                        open={openSI}
                        onClose={() => setOpenSI(false)}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        {projects.map((project) => (
                            <MenuItem key={project.value} onClick={() => handleProjectSelect(project.value.toString())}>
                                {project.label}
                            </MenuItem>
                        ))}
                    </Popover>
                )}
            </MaterialProvider>
        </>
    );
}

export default SendToHiringManagerFromSuperInbox;
