import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ButtonTextWithLoading } from "@/components/ButtonTextWithLoading";
import { updateCandidatesData } from "@/store/reducers/hiring-manager/hiringManager.reducer";
import { checkIfLoading } from "@/store/reducers/loaders.reducer";
import { Button, Modal, Typography } from "@/ui";

type FeedbackModalProps = {
    open: boolean;
    onClose: () => void;
    status: "" | "Shortlisted" | "Rejected";
    candidate: any;
};
function FeedbackModal({ open, onClose, status, candidate }: FeedbackModalProps) {
    const dispatch = useDispatch();
    const params = useParams();
    const [id, setId] = useState<string>(candidate?.candidateId || candidate?.candidateDetails?.[0]?._id || "");
    const [selectedOptions, setSelectedOptions] = useState<string[]>(candidate?.reasons || candidate?.candidateDetails?.[0]?.hiringManager?.reasons || []);
    const [comments, setComments] = useState<string>(
        candidate?.review || candidate?.candidateDetails?.[0]?.hiringManager?.review || ""
    );
    const isLoading = useSelector(checkIfLoading(updateCandidatesData.type));

    useEffect(() => {
        setId(candidate?.candidateId || candidate?.candidateDetails?.[0]?._id || "");
        setSelectedOptions(candidate?.reasons || candidate?.candidateDetails?.[0]?.hiringManager?.reasons || []);
        setComments(candidate?.review || candidate?.candidateDetails?.[0]?.hiringManager?.review || "");
    }, [candidate]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setSelectedOptions((prev) => (checked ? [...prev, name] : prev.filter((option) => option !== name)));
    };

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComments(event.target.value);
    };

    const handleSubmit = () => {
        dispatch(
            updateCandidatesData({
                uuid: params.id,
                candidateReviews: [
                    {
                        candidateId: id,
                        action: status === "Shortlisted" ? "ACCEPTED" : "REJECTED",
                        review: comments,
                        rating: status === "Shortlisted" ? 5 : 1,
                        like: status === "Shortlisted" ? true : false,
                        userName: "Hiring_Manager",
                        reasons: selectedOptions
                    },
                ],
            })
        );
        onClose();
    };

    const handleCancel = () => {
        setSelectedOptions([]);
        setComments("");
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose} showClose style={{ minWidth: "500px" }}>
            {status === "Shortlisted" && (
                <Box onClick={(e) => e.stopPropagation()}>
                    <Typography className="text-[16px] font-semibold">Feedback</Typography>
                    {/* <div
                        className="grid grid-cols-3 gap-[40px] items-center w-[12%]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="p-2 rounded-full">
                            <ThumbUpIcon sx={{ fontSize: "x-large", color: "#03B043", marginRight: "5px" }} />
                        </div>

                        <div className="p-2 rounded-full">
                            <ThumbDownIcon sx={{ fontSize: "x-large", color: "#828283" }} />
                        </div>
                    </div> */}
                    <Typography className="text-[14px] pt-1">What stood out for this candidate?</Typography>

                    <div className="flex flex-col rounded-lg p-4">
                        <label className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4 text-gray-600"
                                name="Role at the current organisation"
                                value="Role at the current organisation"
                                onChange={(e) => handleCheckboxChange(e)}
                                checked={selectedOptions.includes("Role at the current organisation")}
                            />
                            <span className="text-sm text-gray-500">Role at the current organisation</span>
                        </label>

                        <label className="flex items-center space-x-2 mt-2">
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4 text-gray-600"
                                name="Past experience"
                                value="Past experience"
                                onChange={(e) => handleCheckboxChange(e)}
                                checked={selectedOptions.includes("Past experience")}
                            />
                            <span className="text-sm text-gray-500">Past experience</span>
                        </label>
                        <label className="flex items-center space-x-2 mt-2">
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4 text-gray-600"
                                name="Educational background"
                                value="Educational background"
                                onChange={(e) => handleCheckboxChange(e)}
                                checked={selectedOptions.includes("Educational background")}
                            />
                            <span className="text-sm text-gray-500">Educational background</span>
                        </label>
                        <label className="flex items-center space-x-2 mt-2">
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4 text-gray-600"
                                name="Skills & Certifications"
                                value="Skills & Certifications"
                                onChange={(e) => handleCheckboxChange(e)}
                                checked={selectedOptions.includes("Skills & Certifications")}
                            />
                            <span className="text-sm text-gray-500">Skills & Certifications</span>
                        </label>
                        <label className="flex items-center space-x-2 mt-2">
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4 text-gray-600"
                                name="Other"
                                value="Other"
                                onChange={(e) => handleCheckboxChange(e)}
                                checked={selectedOptions.includes("Other")}
                            />
                            <span className="text-sm text-gray-500">Other</span>
                        </label>
                    </div>

                    <Box sx={{ mt: 2 }}>
                        <Typography className="text-[14px]">Feedback note</Typography>
                        <textarea
                            style={{
                                fontSize: "13px",
                                border: "1px solid lightgrey",
                                width: "100%",
                                padding: 3,
                                height: "100px",
                            }}
                            value={comments}
                            onChange={(e: any) => handleTextChange(e)}
                        />
                    </Box>
                    <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end", gap: 1 }}>
                        <Button variant="outline" onClick={handleCancel}>
                            Skip
                        </Button>
                        <Button onClick={handleSubmit}>
                            <ButtonTextWithLoading isLoading={isLoading} text="Submit" variant="light" />
                        </Button>
                    </Box>
                </Box>
            )}
            {status === "Rejected" && (
                <Box onClick={(e) => e.stopPropagation()}>
                    <Typography className="text-[20px] font-semibold">Feedback</Typography>
                    {/* <div
                        className="grid grid-cols-3 gap-[40px] items-center w-[12%]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="p-2 rounded-full">
                            <ThumbUpIcon sx={{ fontSize: "x-large", color: "#828283", marginRight: "5px" }} />
                        </div>

                        <div className="p-2 rounded-full">
                            <ThumbDownIcon sx={{ fontSize: "x-large", color: "#e20027" }} />
                        </div>
                    </div> */}

                    <Typography className="text-[14px] pt-1">Why did you reject this candidate?</Typography>

                    <div className="flex flex-col bg-white rounded-lg p-4">
                        <label className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4 text-gray-600"
                                name="Experience level is not suitable for the role"
                                value="Experience level is not suitable for the role"
                                onChange={(e) => handleCheckboxChange(e)}
                                checked={selectedOptions.includes("Experience level is not suitable for the role")}
                            />
                            <span className="text-sm text-gray-500">Experience level is not suitable for the role</span>
                        </label>
                        <label className="flex items-center space-x-2 mt-2">
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4 text-gray-600"
                                name="Doesn’t satisfy the skillset/certifications requirement"
                                value="Doesn’t satisfy the skillset/certifications requirement"
                                onChange={(e) => handleCheckboxChange(e)}
                                checked={selectedOptions.includes(
                                    "Doesn’t satisfy the skillset/certifications requirement"
                                )}
                            />
                            <span className="text-sm text-gray-500">
                                Doesn’t satisfy the skillset/certifications requirement
                            </span>
                        </label>
                        <label className="flex items-center space-x-2 mt-2">
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4 text-gray-600"
                                name="The current or previous workplace(s) is/are not suitable"
                                value="The current or previous workplace(s) is/are not suitable"
                                onChange={(e) => handleCheckboxChange(e)}
                                checked={selectedOptions.includes(
                                    "The current or previous workplace(s) is/are not suitable"
                                )}
                            />
                            <span className="text-sm text-gray-500">
                                The current or previous workplace(s) is/are not suitable
                            </span>
                        </label>
                        <label className="flex items-center space-x-2 mt-2">
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4 text-gray-600"
                                name="Other candidates in the pipeline seem better for the role"
                                value="Other candidates in the pipeline seem better for the role"
                                onChange={(e) => handleCheckboxChange(e)}
                                checked={selectedOptions.includes(
                                    "Other candidates in the pipeline seem better for the role"
                                )}
                            />
                            <span className="text-sm text-gray-500">
                                Other candidates in the pipeline seem better for the role
                            </span>
                        </label>
                        <label className="flex items-center space-x-2 mt-2">
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4 text-gray-600"
                                name="Other"
                                value="Other"
                                onChange={(e) => handleCheckboxChange(e)}
                                checked={selectedOptions.includes("Other")}
                            />
                            <span className="text-sm text-gray-500">Other</span>
                        </label>
                    </div>

                    <Box sx={{ mt: 2 }}>
                        <Typography className="text-[16px]">Why is this candidate unsuitable? </Typography>

                        <textarea
                            style={{
                                fontSize: "13px",
                                border: "1px solid lightgrey",
                                width: "100%",
                                padding: "5px",
                                height: "100px",
                            }}
                            value={comments}
                            onChange={(e: any) => handleTextChange(e)}
                        />
                    </Box>
                    <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end", gap: 1 }}>
                        <Button variant="outline" onClick={handleCancel}>
                            Skip
                        </Button>
                        <Button onClick={handleSubmit}>
                            <ButtonTextWithLoading isLoading={false} text="Submit" variant="light" />
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    );
}

export default FeedbackModal;
